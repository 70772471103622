<template>
    <div>
      <TableGroup
        id="InvitationSent"
        :items="invitationList"
        :isLoading="isTableLoading"
        :pagination="pagination"
        :searchText="queryFilters.searchText"
        :showExportButton="false"
        @handleSearch="handleSearchByInput"
        @handleSearchByFilter="handleSearch"
        @handlePaginate="handleSearch"
        @handleCleanSearch="handleCleanSearch"
        >
        <template v-slot:content>
            <RequestFilters
              @handleChangeCreatedAt="handleChangeCreatedAt"
              @handleChangeOrigin="handleChangeOrigin"
              @handleChangeSpecialty="handleChangeSpecialty"
              @handleChangeClinic="handleChangeClinic"
              @handleChangeStage="handleChangeStage"
              @handleChangeStatus="handleChangeStatus"
              :filters="queryFilters"
            />
        </template>

        <InvitationsSentTable
            ref="requestTable"
            :invitations="invitationList"
            :canDelete="!isDoctor"
            canEdit
            :canCopy="!isDoctorOrClinic"
            :showActions="!isDoctor"
            @handleCancel="handleCancel"
            @handleSelectRow="handleSelectRow"
            @handleSortAsc="handleSortAsc"
            @handleSortDesc="handleSortDesc"
        />
      </TableGroup>
       <!-- MODALS START -->
      <RequestDetail
        id="request-detail"
        :request="requestData"
        :invitationStatus="invitation.status"
        :invitationSender="true"
        :invitationId="invitation.id"
        @handleChangeRequest="handleChangeRequest"
        @handleCancel="hideDetailView"
        @handleCancelInvitation="handleCancel"
      />
      <InvitationCancelModal
        id="InvitationCancel_Modal"
        ref="InvitationCancel_Modal"
        :invitation="currentInvitationId"
        @handleCancelInvitation="handleCancelInvitation"
      />
    </div>
</template>
<script>
import {
  getSortType,
  SORT_TYPE,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getOrdenation } from '@/helpers/ApiHelper';

export default {
  props: {},
  data() {
    return {
      invitationList: [],
      invitation: {},
      requestData: {},
      currentInvitationId: '',
      pagination: {},
      showDetail: false,
      isTableLoading: false,
      queryFilters: {
        searchText: null,
        createdAt: null,
        origin: null,
        specialty: null,
        clinic: null,
        stage: null,
        status: null,
        sort: {
          name: 'createdAt',
          type: 'desc',
        },
      },
    };
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;
      this.$store.dispatch('invitations/getInvitations', this.getSearchInvitationData(page))
        .then((res) => {
          this.invitationList = res.invitationList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => { this.isTableLoading = false; });
    },
    getSearchInvitationData(page = 1) {
      return {
        page,
        perPage: 10,
        filters: this.getInvitationSearchFilters(),
        ordenation: getOrdenation(this.queryFilters.sort),
      };
    },
    getInvitationSearchFilters() {
      const filtersGroup = [];

      filtersGroup.push('type:clinic');

      if (this.queryFilters.searchText !== null) {
        if (!this.checkText()) {
          filtersGroup.push(
            `name:${this.queryFilters.searchText}`,
          );
        } else {
          filtersGroup.push(
            `caseNumber:${this.queryFilters.searchText}`,
          );
        }
      }

      if (this.queryFilters.status !== null) {
        filtersGroup.push(
          `status:${this.queryFilters.status}`,
        );
      }

      return filtersGroup;
    },
    cleanFilters() {
      this.queryFilters = {
        searchText: null,
        status: null,
      };
    },

    // Show / Hide
    showDetailView() {
      this.showDetail = true;
    },
    hideDetailView() {
      this.showDetail = false;
    },

    // Table Handlers
    handleChangeRequest(id, status) {
      if (status === STATUS.ACCEPTED.key) {
        this.changeRequestStatus(id, status, true);
      } else {
        this.changeRequestStatus(id, status, false);
      }

      this.hideDetailView();
      this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
    },
    changeRequestStatus(id, status, isAccepted) {
      const rIndex = this.invitationList.findIndex((item) => item.request.id === id);
      this.invitationList[rIndex].request.status = status;
      this.invitationList[rIndex].request.accepted = isAccepted;
    },
    handleCleanSearch() {
      this.cleanFilters();
      this.handleSearch();
    },
    setInvitation(data) {
      this.invitation = data;
    },
    setRequest(data, hasClinic = true) {
      this.requestData = this.getNewRequest(data, hasClinic);
    },
    getNewRequest(data, hasClinic = true) {
      const newRequest = {
        id: data.id,
        caseNumber: data.caseNumber,
        instance: data.instance,
        level: data.level,
        hasInsurance: data.hasInsurance,
        hasRecentReports: data.hasRecentReports,
        patient: data.patient,
        createdAt: data.createdAt,
        specialty: data.specialty,
        pathology: data.pathology,
        medicalProcedure: data.medicalProcedure,
        clinic: hasClinic ? data.clinic : null,
        doctor: data.doctor,
        doctors: data.doctors,
        requestStage: data.requestStage,
        status: data.status.toLowerCase(),
        healthManager: data.healthManager,
        documents: [...data.documents],
        notes: data.notes,
        appointments: data.appointments,
        owner: data.owner,
        accepted: data.accepted,
        collaborators: data.collaborators,
        invitations: data.invitations,
      };

      return newRequest;
    },
    handleSelectRow(item) {
      this.setInvitation(item);
      this.setRequest(item.request);
      this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
    },
    handleSortAsc(param) {
      this.queryFilters.sort.type = this.queryFilters.sort.name === param
        ? getSortType(this.queryFilters.sort.type)
        : SORT_TYPE.ASC;
      this.queryFilters.sort.name = param;

      this.handleSearch();
    },
    handleSortDesc(param) {
      this.queryFilters.sort.type = this.queryFilters.sort.name === param
        ? getSortType(this.queryFilters.sort.type)
        : SORT_TYPE.DESC;
      this.handleSearch();
    },
    handleCancel(invitationId) {
      this.currentInvitationId = invitationId;
      this.$bvModal.show('InvitationCancel_Modal');
    },
    handleCancelInvitation(invitationId) {
      this.$store.dispatch('invitations/cancelInvitation', invitationId)
        .then(() => {
          this.$bvModal.hide('InvitationCancel_Modal');
          const rIndex = this.invitationList.findIndex((item) => item.id === invitationId);
          this.invitationList[rIndex].status = STATUS.CANCELED.key;
        })
        .catch(() => {
          this.$bvModal.hide('InvitationCancel_Modal');
        });
    },

    // Field Handlers
    handleSearchByInput(text) {
      if (text !== null) {
        this.queryFilters.searchText = text;
        this.handleSearch();
      } else {
        this.queryFilters.searchText = null;
      }
    },
    handleChangeCreatedAt(value) {
      this.queryFilters.createdAt = value;
    },
    handleChangeOrigin(value) {
      this.queryFilters.origin = value;
    },
    handleChangeSpecialty(value) {
      this.queryFilters.specialty = value;
    },
    handleChangeClinic(value) {
      this.queryFilters.clinic = value;
    },
    handleChangeStage(value) {
      this.queryFilters.stage = value;
    },
    handleChangeStatus(value) {
      this.queryFilters.status = value;
    },
    checkText() {
      return /\d/.test(this.queryFilters.searchText);
    },
  },
  computed: {
    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },
    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
    isDoctorOrClinic() {
      return this.isDoctor || this.isClinic;
    },
  },
  components: {
    TableGroup: () => import('@/components/molecules/Tables/TableGroup/TableGroup.vue'),
    RequestFilters: () => import('@/components/organisms/Request/RequestFilters.vue'),
    InvitationsSentTable: () => import('@/components/molecules/Tables/InvitationsTable/InvitationsSentTable.vue'),
    RequestDetail: () => import('@/components/organisms/Request/RequestDetail.vue'),
    InvitationCancelModal: () => import('@/components/molecules/Modal/Collaborations/InvitationCancelModal.vue'),
  },
};
</script>
